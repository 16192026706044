import * as React from "react";
import Layout from "src/components/layout";
import Button from "src/components/button";

const PageComponent = ({ location, copy, pageTitle, lang }) => {
  const prefix = lang === "en" ? "" : `/${lang}`;
  return (
    <Layout pageTitle={pageTitle} location={location} lang={lang}>
      <section className="inner-content mx-auto my-10 mb-20">
        <div className="inline-block mb-10">
          <Button arrow reverse href={`${prefix}/get-a-quote`}>
            {copy.backLink}
          </Button>
        </div>
        <table className="text-sm">
          <thead>
            <tr className="bg-gray-50">
              <td></td>
              {copy.headerRow.map((header, key) => (
                <th className="p-1 text-left border border-gray-200" key={key}>
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {copy.rows.map((row, key) => (
              <tr key={key} className={`${key % 2 === 1 ? "bg-gray-50" : ""}`}>
                <th className="p-1 text-left border border-gray-200">
                  {row.header}
                </th>
                {row.cells.map((cell, key) => (
                  <td
                    key={key}
                    className="p-1 border border-gray-200"
                    colSpan={cell.colspan || 1}
                  >
                    {cell._ || cell}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td className="p-1 border border-gray-200 bg-gray-50" colSpan="7">
                {copy.footNote}
              </td>
            </tr>
          </tfoot>
        </table>
      </section>
    </Layout>
  );
};

export default PageComponent;
